import pick from 'lodash/pick';

export const saveLocal = (name, obj) => {
  localStorage.setItem(name, JSON.stringify(obj));
};

export const getFromLocalStorage = (obj) => {
  if (obj) {
    return JSON.parse(localStorage.getItem(obj));
  }
};

export const replaceRecipientName = (name) => {
  const userDetails = getFromLocalStorage('userDetails');

  if (userDetails) {
    const userName = pick(userDetails, ['firstName']);

    const walkText = (node) => {
      if (node.nodeType === 3) {
        node.data = node.data.replace(
          /RECIPIENT_NAME/g,
          capitalizeFirstLetter(userName['firstName'])
        );
      }
      if (node.nodeType === 1 && node.nodeName !== 'SCRIPT') {
        for (var i = 0; i < node.childNodes.length; i++) {
          walkText(node.childNodes[i]);
        }
      }
    };

    walkText(document.body);
  }
};

//this needs to be made more modular or renamed, it's quite ambiguous
export const replacePartnerName = (name) => {
  const userDetails = getFromLocalStorage('userDetails');

  if (userDetails) {
    const userName = pick(userDetails, ['partnerFirstName']);

    const walkText = (node) => {
      if (node.nodeType === 3) {
        node.data = node.data.replace(
          /P_NAME/g,
          capitalizeFirstLetter(userName['partnerFirstName'])
        );
      }
      if (node.nodeType === 1 && node.nodeName !== 'SCRIPT') {
        for (var i = 0; i < node.childNodes.length; i++) {
          walkText(node.childNodes[i]);
        }
      }
    };

    walkText(document.body);
  }
};

//this needs to be made more modular or renamed, it's quite ambiguous
export const capitalizeFirstLetter = (name) => {
  if (name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
};

export const formatMeasurements = (unit, type) => {
  if (type === 'height') {
    return `${(unit / 30.48).toFixed(2)} ft/in / ${unit} cm`;
  }

  if (type === 'weight') {
    return `${(unit / 6.35).toFixed(2)} Stone/LLbs / ${unit} Kg`;
  }
};
